
.signUp-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.signUp-wrapper .title{
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}
.signUp-wrapper .logo b{
    font-size: 46px;
}
.signUp-wrapper .inputBox:nth-child(1){
    margin-top: 80px;
}
.signUp-wrapper .inputBox-headText{
    padding-left: 6px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 6px;
    width: 400px;
}
.signUp-wrapper .inputBox .text-field{
    margin-bottom: 20px;
    width: 400px;
    height: 50px;
    background-color: #ffffff;
    outline: none;
    border: none;
    padding: 0px 16px;
    box-shadow: 0px 2px 8px 2px rgb(0, 0, 0, 0.15);
    border-radius: 6px;
}
.signUp-wrapper .inputBox .text-field::placeholder{
    font-weight: 500;
    font-family: "Pretendard";
}
.signUp-wrapper .inputBox-pw .text-field{
    margin-bottom: 0;
}

.signUp-wrapper .inputBox-name .text-field{
    margin-bottom: 0;
}

.signUp-wrapper .warningMsg{
    font-size: 12px;
    color: #FF7474;
    font-weight: 800;
    margin-top: 10px;
    margin-bottom: 20px;
}

.signUp-wrapper .inputBox-name .warningMsg {
    font-size: 12px;
    color: #FF7474;
    font-weight: 800;
    margin-top: 10px;
    margin-bottom: 20px;
}

.signUp-wrapper .select-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.signUp-wrapper .inputBox-userIfo .css-b62m3t-container{
    width: calc(33% - 6px);
    height: 36px;
    outline: none;
    border: none;
}
.signUp-wrapper .inputBox-userIfo .css-13cymwt-control{
    border: none;
    box-shadow: 0px 2px 8px 2px rgb(0, 0, 0, 0.15);
    border-radius: 6px;
}
.signUp-wrapper .inputBox-userIfo .css-1u9des2-indicatorSeparator{
    display: none;
}
.signUp-wrapper .inputBox-userIfo .css-1jqq78o-placeholder{
    font-size: 14px;
    font-weight: 500;
    font-family: "Pretendard";
}
.signUp-wrapper .btnOk{
    width: 400px;
    height: 50px;
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    background-color: #3E3E3E;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 6px;
}
.signUp-wrapper .btn-wrap .btn{
    width: 75px;
    height: 20px;
    border-radius: 50px;
}
.signUp-wrapper .inputBox-id .inputBox-id-container{
    position: relative;
}
.signUp-wrapper .overlapCheck{
    color: #ffffff;
    background-color: #546df1;
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    transform: translateY(-80%);
    right: 12px;
    cursor: pointer;
}
.signUp-wrapper .inputBox-tel{
    display: flex;
    flex-direction: column;
    position: relative;
}
.signUp-wrapper .inputBox-tel .check-form{
    margin-bottom: 10px;
}

.signUp-wrapper .inputBox-name {
    position: relative;
}

.signUp-wrapper .inputBox-name .overlapCheck{
    top: 28%;
    transform: translateY(0%);
}

.signUp-wrapper .inputBox-tel .overlapCheck.check1{
    top: 22%;
    transform: translateY(0%);
}
.signUp-wrapper .inputBox-tel .overlapCheck.check2{
    top: 60%;
    transform: translateY(0%);
}
.signUp-wrapper .inputBox-email{
    position: relative;
}
.signUp-wrapper .inputBox-email .overlapCheck{
    top: 35%;
    transform: translateY(0%);
}
.signUp-wrapper .inputBox-checkPw.inputBox .text-field{
    margin-bottom: 0;
}
.signUp-wrapper .inputBox-pw .inputBox-headText{
    display: flex;
    align-items: flex-end;
}
.signUp-wrapper .inputBox-pw .inputBox-headText p{
    margin-left: 16px;
    font-size: 12px;
    color: #FF7474;
    font-weight: 800;
}
