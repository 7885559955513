.login-background{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 99;
}
.login-container{
    padding: 40px;
    background-color: #ffffff;
    border-radius: 20px;
    position: relative;
}
.login-container .close-btn{
    position: absolute;
    top: 24px;
    right: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e7e7e7;
}
.login-container .close-btn svg{
    width: 80%;
    height: 80%;
    fill: #000000;
}

.login-container .logo{
    height: 90px;
    margin: 0 auto;
    width: 260px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.login-container .logo img{
    width: 260px;
    height: 260px;
}
.login-container .login-wrapper .topBox{
    display: flex;
    border: 1px solid #E1E3E5;
    border-bottom: 0px;
    border-radius: 12px;
    margin-top: 30px;
    width: 215px;
    height: 70px;
}
.login-container .login-wrapper .topBox .loginIcon{
    width: 16px;
    height: 16px;
    margin-right: 36px;
    margin-left: 28px;
    margin-top: 20px;
}
.login-container .login-wrapper .topBox .loginText{
    font-weight: 500;
    margin-top: 20px;
}
.login-container .login-wrapper .bottomBox{
    border: 1px solid #E1E3E5;
    border-radius: 12px;
    margin-top: -12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 18px;
}
.login-container .login-wrapper .bottomBox .InputBox{
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 120px;
    overflow: hidden;
    border: 1px solid #C5CCD2;
    border-radius: 12px;
    background-color: #fff;
    margin-top: 16px;
}
.login-container .login-wrapper .bottomBox .InputBox .login-id,
.login-container .login-wrapper .bottomBox .InputBox .login-pw{
    width: 100%;
    height: 60px;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding-left: 16px;
    font-size: 18px;
}
.login-container .login-wrapper .bottomBox .InputBox .login-id::placeholder,
.login-container .login-wrapper .bottomBox .InputBox .login-pw::placeholder{
    font-size: 16px;
}
.login-container .login-wrapper .bottomBox .InputBox hr{
    margin: 0;
    border: none;
    border-top: 1px solid #C5CCD2;
}
.login-container .login-wrapper .bottomBox .autoLogin{
    width: 100%;
    text-align: left;
    display: flex;
    margin-left: 48px;
    margin-top: 12px;
}
.login-container .login-wrapper .bottomBox .autoLogin .autoLoginCheckBtn{
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 8px;
}
.login-container .login-wrapper .bottomBox .loginBtn{
    width: 400px;
    height: 50px;
    margin-top: 28px;
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    background-color: #3E3E3E;
    border-radius: 12px;
    cursor: pointer;
}
.login-container .login-wrapper .wall{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 40px 0px;
}
.login-container .login-wrapper .wall div{
    width: 50%;
    height: 1px;
    background-color: #3E3E3E;
}
.login-container .login-wrapper .wall p{
    font-size: 16px;
    font-weight: 500;
    color: #3E3E3E;
    text-wrap: nowrap;
    padding: 0px 12px;
}
.login-container .login-wrapper .socialLogin{
    display: flex;
    align-items: center;
}
.login-container .login-wrapper .socialLogin .socialLogin-btn{
    width: 60px;
    height: 60px;
    margin-left: 20px;
}
.login-container .login-wrapper .socialLogin .socialLogin-btn:nth-child(1){
    margin-left: 0px;
}

.login-container .helpBtn-wrapper{
    display: flex;
    margin-top: 20px;
    color: #888888;
    margin-right: 12px;
}
.login-container .helpBtn-wrapper div{
    cursor: pointer;
}

.login-container .helpBtn-wrapper .findPw,
.login-container .helpBtn-wrapper .findId{
    margin-right: 12px;
    display: flex;
    align-items: center;
}
.login-container .helpBtn-wrapper .findPw::after,
.login-container .helpBtn-wrapper .findId::after {
    content: "";
    display: inline-block;
    width: 2px;
    height: 75%;
    border-right: 2px solid #DADADA;
    margin-left: 12px;
}
.login-container .helpBtn-wrapper .signUp{
    cursor: pointer;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    border-radius: 8px;
    max-width: 800px;
    max-height: 100vh;
    position: relative;
    cursor: default;
    border-radius: 20px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #e74c3c;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
}
