.footer{
    width: 100%;
    margin-top: 100px;
}
.footer .f-top{
    width: 100%;
    height: 48px;
    padding: 0px 360px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #3E3E3E;
}
.footer .f-top .left{
    display: flex;
}
.footer .f-top .left p{
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
}
.footer .f-top .left .wall{
    margin: 0px 20px;
    width: 1px;
    height: 16px;
    background-color: #cacaca;
}
.footer .f-top .icons{
    display: flex;
    align-items: center;
}
.footer .f-top .icons img{
    margin-left: 30px;
}
.footer .f-top .icons .facebook{
    width: 10px;
    height: 21px;
}
.footer .f-top .icons .instagram{
    width: 19px;
    height: 19px;
}
.footer .f-top .icons .youtube{
    width: 23px;
    height: 17px;
}
.footer .f-mid{
    background-color: #525252;
    height: 320px;
    padding: 0px 360px 40px;
    display: flex;
    justify-content: space-between;
}
.footer .f-mid .left{
    margin-top: auto;
}
.footer .f-mid .left .address{
    margin-bottom: 6px;
}
.footer .f-mid .left p{
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
}
.footer .f-mid .right{
    margin-top: auto;
}
.footer .f-mid .right .title{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #ffffff;
}
.footer .f-mid .right .title img{
    margin-left: 8px;
    width: 22px;
    height: 22px;
}
.footer .f-mid .right p{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 6px;
    color: #dfdfdf;
}
.footer .f-mid .right .email{
    margin-bottom: 28px;
    text-decoration: underline;
}
.footer .f-mid .right .title.mid{
    margin-bottom: 34px;
}
.footer .f-mid .right .num{
    font-size: 30px;
    font-weight: 600;
    color: #CBCBCB;
}
.footer .f-bot{
    height: 48px;
    width: 100%;
    background-color: #525252;
    border-top: 1px solid #707070;
}
