.userInfoContainer{
    display: flex;
    position: relative;
}

.userProfileWrapper {
    position: fixed;
    margin-top: 200px;
    margin-left: 300px;
}
  
.userProfileWrapper .userProfileTab{
    background-color: #4a4a4a;
    color: white;
    padding: 20px 60px;
    font-weight: bold;
    position: absolute;
    top: -20px;
    left: -10px;
    border-radius: 5px 5px 0 0;
}

.userProfileWrapper .tailBox{
    width: 10px;
    height: 10px;
    position: absolute;
    top: 38.5px;
    left: -10px;
}
  
.userProfileWrapper .userProfileContainer {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    height: 500px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.userProfileWrapper .profileIcon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 auto 10px;
}
  
.userProfileWrapper .profileName {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
}
  
.userProfileWrapper .profileEmail {
    color: #888;
    font-size: 14px;
    margin-bottom: 10px;
}
.userProfileWrapper .ch-img{
    display: flex;
    align-items: center;
}
.userProfileWrapper .change-btn{
    background-color: #f0f0f0;
    border: none;
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    font-weight: 400;
}
.userProfileWrapper .change-btn:hover{
    background-color: #ddd;
}
.userProfileWrapper .change-btn.delete-img{
    margin-left: 6px;
}


.profileContainer{
    display: flex;
    flex-direction: column;
    margin-top: 200px;
    margin-left: 800px;
}
.profileInfoContainer {
    width: 800px; 
    border: 1px solid #121212;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 30px 10px;
    background-color: #fff;
}
  
.profileInfoContainer .headText,
.myLikeContainer .headText,
.myRecentContainer .headText{
    font-weight: bold;
    margin-bottom: 20px;
}
  
.profileInfoContainer .profileInfo {
    display: flex;
    flex-direction: column;
}
  
.profileInfoContainer .profileRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 0;
    border-bottom: 1px solid #CECECE; 
}
.profileInfoContainer .profileRow:last-child {
    border-bottom: none;
}
.profileInfoContainer .profileIcon.profilePhoto{
    width: 40px;
    height: 40px;
    min-width: 40px;
}
  
.profileInfoContainer .profileIcon {
    width: 40px;
    height: 20px;
    border-radius: 50%;
    margin-right: 15px;
}
.profileInfoContainer .profileDetails-box{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.profileInfoContainer .profileDetails {
    flex-grow: 1;
    text-align: left;
}
.profileInfoContainer .profileText {
    color: #888;
    font-size: 14px;
    text-align: left;
    flex-grow: 1;
}

  
.profileInfoContainer .profileName {
    font-weight: bold;
}
.profileInfoContainer .input-change{
    height: 100%;
    border: 2px solid #6b6b6b;
    padding-left: 6px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.profileInfoContainer .profileEmail,
  .profileText {
    color: #888;
    font-size: 14px;
}
.profileInfoContainer .change-emailbox{
    display: flex;
    width: 100%;
}
.profileInfoContainer .change-emailbox svg{
    width: 24px;
    height: 24px;
    margin: 0px 10px;
}
.profileInfoContainer .email-check1,
.profileInfoContainer .email-check2,
.profileInfoContainer .email-change-btns{
    display: flex;
    align-items: center;
}
.profileInfoContainer .email-check1 .actionButton,
.profileInfoContainer .email-check2 .actionButton{
    margin-left: 6px;
    background-color: #4c90e6;
    color: #ffffff;
    font-weight: 400;
}
.profileInfoContainer .email-check1 .actionButton:hover,
.profileInfoContainer .email-check2 .actionButton:hover{
    background-color: #8abaff;
}
.profileInfoContainer .email-change-btns{
    margin-left: auto;
}
.profileInfoContainer .email-change-btns .cancle-btn{
    margin-left: 10px;
}
.profileInfoContainer .email-check1 .email-change{
    width: 190px;
    font-size: 14px;
}
.profileInfoContainer .email-check2 .email-code{
    width: 120px;
}
.profileInfoContainer .email-textbox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.profileInfoContainer .actionButton {
    background-color: #f0f0f0;
    border: none;
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    font-weight: 400;
    height: min-content;
}
.profileInfoContainer .actionButton.cancle{
    margin-left: 6px;
}
  
.profileInfoContainer .actionButton:hover {
    background-color: #ddd;
}

.myLikeContainer{
    width: 800px;
    min-height: 200px;
    border: 1px solid #121212;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
    margin-top: 40px;
    padding-right: 0;
    position: relative;
}
.profileContainer .likedEvents-x{
    width: 100%;
    text-align: center;
    line-height: 80px;
}
.myLikeContainer .likeSwiper .slide-content{
    width: 200px;
    height: 250px;
    border-radius: 10px;
}
.myLikeContainer .likeSwiper-wrapper{
    display: flex;
}
.myLikeContainer .likeSwiper-wrapper .like-button-wrapper{
    display: flex;
    position: absolute;
    bottom: 20px;
    left: 16px;
}
.myLikeContainer .likeSwiper-wrapper .like-button-prev,
.myLikeContainer .likeSwiper-wrapper .like-button-next{
    width: 36px;
    height: 36px;
}
.myLikeContainer .likeSwiper-wrapper .like-button-prev{
    margin-right: 4px;
}
.myLikeContainer .likeSwiper-wrapper .like-button-next{
}
.myLikeContainer .likeSwiper{
    margin-left: 110px;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.myLikeContainer .likeSwiper .slide1{
    background-color: salmon;
}
.myLikeContainer .likeSwiper .slide2{
    background-color: sandybrown;
}
.myLikeContainer .likeSwiper .slide3{
    background-color: seagreen;
}
.myLikeContainer .likeSwiper .slide4{
    background-color: skyblue;
}
.myLikeContainer .likeSwiper .slide5{
    background-color: seashell;
}
.myLikeContainer .likeSwiper .slide6{
    background-color: sienna;
}
.myLikeContainer .likeSwiper-wrapper .like-btn{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 12px;
    left: 156px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.myLikeContainer .likeSwiper-wrapper .like-btn img{
    width: 25px;
    height: 25px;
}

.myRecentContainer{
    width: 800px;
    border: 1px solid #121212;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
    margin-top: 40px;
}
.myRecentContainer.lastContainer{
    margin-bottom: 100px;
}
.myLikeContainer{
    width: 800px;
    border: 1px solid #121212;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
    margin-top: 40px;
    padding-right: 0;
}
.myLikeContainer .slide-content{
    width: 200px;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

}
.myLikeContainer .slide-content::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(rgba(0,0,0,0) 80%, rgba(0,0,0,0.7));
}
.myLikeContainer .slide-content .slide-content-txt{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 10px;
    z-index: 2;
}
.myLikeContainer .slide-content .slide-content-txt .title{
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    z-index: 2;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.myRecentContainer .recentSwiper-wrapper{
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 210px;
    overflow-y: auto; /* 수직 스크롤 가능 */
    overflow-x: hidden; /* 수평 스크롤 제거 */
}
/* Chrome, Safari 및 Edge에서 스크롤바 스타일 수정 */
.myRecentContainer .recentSwiper-wrapper::-webkit-scrollbar {
    width: 4px; /* 스크롤바 너비를 4px로 설정하여 얇게 */
}

/* 스크롤바 배경 */
.myRecentContainer .recentSwiper-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1; /* 스크롤바 배경 색상 */
    border-radius: 10px; /* 모서리 둥글게 */
}

/* 스크롤바 */
.myRecentContainer .recentSwiper-wrapper::-webkit-scrollbar-thumb {
    background: #888; /* 스크롤바 색상 */
    border-radius: 10px; /* 모서리 둥글게 */
}

/* 스크롤바에 마우스를 올렸을 때 */
.myRecentContainer .recentSwiper-wrapper::-webkit-scrollbar-thumb:hover {
    background: #555; /* 마우스 오버 시 색상 변경 */
}

.myRecentContainer .recentSwiper-wrapper .item {
    flex: 0 0 auto; /* 항목 크기를 고정 */
    margin: 5px 0; /* 항목 간 간격 */
    /* 필요에 따라 추가 스타일링 */
}
.myRecentContainer .recentSwiper-wrapper .name{
    width: 10%;
    font-weight: 500;
    color: #444444;
}
.myRecentContainer .like-comment .content{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 85%;
}
.myRecentContainer .authored-comment-item .content{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85%;
}
.myRecentContainer .authored-comment-item .date{

}

.myRecentContainer .recentSwiper-wrapper .like-comment,
.myRecentContainer .recentSwiper-wrapper .authored-comment-item{
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 10px;
    width: 98%;
    border-bottom: 1px solid #c1c1c1;
}
.myRecentContainer .recentSwiper-wrapper .wall{
    height: 16px;
    width: 1px;
    margin: 0px 10px;
    background: #7d7d7d;
}
.myRecentContainer .recentSwiper-wrapper .recent-button-wrapper{
    display: flex;
    position: absolute;
    top: 210px;
    left: 8px;
}
.myRecentContainer .recentSwiper-wrapper .recent-button-prev,
.myRecentContainer .recentSwiper-wrapper .recent-button-next{
    width: 36px;
    height: 36px;
}
.myRecentContainer .recentSwiper-wrapper .recent-button-prev{
    margin-right: 4px;
}
.myRecentContainer .recentSwiper-wrapper .recent-button-next{
}
.myRecentContainer .recentSwiper{
    margin-left: 110px;
    overflow: hidden;
    position: relative;
}
.myRecentContainer .recentSwiper .slide1{
    background-color: salmon;
}
.myRecentContainer .recentSwiper .slide2{
    background-color: sandybrown;
}
.myRecentContainer .recentSwiper .slide3{
    background-color: seagreen;
}
.myRecentContainer .recentSwiper .slide4{
    background-color: skyblue;
}
.myRecentContainer .recentSwiper .slide5{
    background-color: seashell;
}
.myRecentContainer .recentSwiper .slide6{
    background-color: sienna;
}
.myRecentContainer .recentSwiper-wrapper .like-btn{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 12px;
    left: 156px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.myRecentContainer .recentSwiper-wrapper .like-btn img{
    width: 25px;
    height: 25px;
}