.headerbar{
    height: 90px;
}
.noticepage .banner{
    height: 397px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.noticepage .banner::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 1;
}
.noticepage .banner .banner-title{
    font-size: 46px;
    color: #ffffff;
    font-weight: 600;
    z-index: 2;
}
.noticepage .article{
    margin: 70px 400px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.noticepage .article .wriht-box{
    display: flex;
    align-items: flex-end;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}
.noticepage .article .wriht-box .write-btn{
    text-wrap: nowrap;
    background-color: #2b2b2b;
    border: none;
    padding: 10px 18px;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 500;
    cursor: pointer;
}
.noticepage .article .post-count{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    width: 100%;
}
.noticepage .article .post-list{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.noticepage .article .post-list .notices-x{
    width: 100%;
    margin: 30px 0px;
    text-align: center;
}
.noticepage .article .post-list .thead{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #3E3E3E;
}
.noticepage .article .post-list .thead .th-title{
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
}
.noticepage .article .post-list .thead .th-title.th1{
    min-width: 10%;
    text-align: center;
}
.noticepage .article .post-list .thead .th-title.th2{
    min-width: 50%;
    text-align: center;
}
.noticepage .article .post-list .thead .th-title.th3{
    min-width: 18%;
    text-align: center;
}
.noticepage .article .post-list .thead .th-title.th4{
    min-width: 12%;
    text-align: center;
}
.noticepage .article .post-list .thead .th-title.th5{
    min-width: 10%;
    text-align: center;
}

.noticepage .article .post-list .post{
    height: 68px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D8D8D8;
}
.noticepage .article .post-list .post p{
    font-size: 16px;
    font-weight: 400;
    color: #333333;
}
.noticepage .article .post-list .post .division{
    font-weight: 500;
    min-width: 10%;
    text-align: center;
}
.noticepage .article .post-list .post .post-title{
    min-width: 50%;
}
.noticepage .article .post-list .post .author{
    min-width: 18%;
    text-align: center;
}
.noticepage .article .post-list .post .creation-date{
    min-width: 12%;
    text-align: center;
}
.noticepage .article .post-list .post .viewcnt{
    min-width: 10%;
    text-align: center;
}
.noticepage .article .pagination{
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 50px;
}
.noticepage .article .pagination .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
}
.noticepage .article .pagination .btn svg{
    width: 20px;
    height: 20px;
    fill: #555555;
}
.noticepage .article .pagination .btn.active svg{
    fill: #c5c5c5;
}
.noticepage .article .pagination .prevbtn svg{
    transform: rotate(180deg);
} 

.noticepage .article .pagination .pagenumber{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 320px;
}
.noticepage .article .pagination .num{
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
    font-weight: 400;
    color: #111111;
    cursor: pointer;
}
.noticepage .article .pagination .num.active{
    background-color: #3E3E3E;
    color: #ffffff;
}
.noticepage .article .post-search{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.noticepage .article .post-search .css-b62m3t-container{
    margin-right: 10px;
}/*Select */

.noticepage .article .post-search .css-1u9des2-indicatorSeparator{
    display: none;
}
.noticepage .article .post-search .css-13cymwt-control{
    width: 180px;
    height: 52px;
    border-radius: 0px;
}
.noticepage .article .post-search .css-t3ipsp-control{
    min-width: 180px;
    min-height: 52px;
    border-radius: 0px;
}
.noticepage .article .post-search .search-text{
    width: 350px;
    height: 52px;
    border: 1px solid #cccccc;
    padding: 4px 50px 4px 12px;
}
.noticepage .article .post-search .submit-btn{
    position: absolute;
    right: 21%;
    top: 50%;
    transform: translateY(-50%);
}
.noticepage .article .post-search .submit-btn svg{
    width: 30px;
    height: 30px;
}
.noticepage .article .post-search .all-search{
    background-color: #3e3e3e;
    color: #fff;
    padding: 0 40px;
    flex-shrink: 0;
    height: 52px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    font-weight: 500;
}
