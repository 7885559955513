.noticle-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}
input:focus {
    outline: none;
}
textarea:focus{
    outline: none;
}

.notice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notice .headText {
    letter-spacing: -1px;
    font-size: 32px;
    text-decoration: underline;
    font-weight: 800;
    color: #333333;
    margin-top: 100px;
    margin-bottom: 64px;
}

.notice .createDate {
    font-size: 14px;
    color: #333333;
    width: 1200px;
    text-align: right;
    margin-bottom: 10px;
}

.notice-container {
    width: 1200px;
    padding: 48px 56px;
    background-color: #f7f7f7;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.notice-container .file-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.notice-container .file-container .name-container{
    display: flex;
    align-items: center;
}
.notice-container .file-container .name-container .title{
    margin-right: 10px;
}
.notice-container .file-container .name-box{
    display: flex;
    align-items: center;
}
.notice-container .file-container .name-box:nth-child(1){
    margin-bottom: 4px;
}
.notice-container .file-container .name-box p{
    margin-right: 4px;
    color: #555555;
}
.notice-container .file-container .name-box button{
    font-size: 12px;
    background-color: #b5b5b5;
    color: #ffffff;
    padding: 4px 6px;
    border-radius: 4px;
    margin-right: 10px;
}
.notice-container .select{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.notice-container .select .css-13cymwt-control{
    width: 140px;
}
.notice-container .select .css-t3ipsp-control{
    min-width: 140px;
}

.noticeTitleBox, .noticeContentBox, .contentCreateDate {
    display: flex;
    align-items: center;
}

.noticeTitleBox .headTextBox, .noticeContentBox .headTextBox, .contentCreateDate .headTextBox {
    background-color: #3e3e3e;
    color: #fff;
    padding: 0 40px;
    flex-shrink: 0;
    height: 60px;
    display: flex;
    align-items: center;
}
.notice-container .file-btn{
    display: flex;
    align-items: center;
}

.notice-container .file-btn .btn{
    background-color: #3e3e3e;
    color: #fff;
    padding: 0 40px;
    flex-shrink: 0;
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.notice-container .noticeContentBox{
    flex-direction: column;
}
.notice-container .noticeContentBox .headTextBox{
    width: 100%;
    justify-content: center;
}
.notice-container .noticeContentBox .contentText {
    width: 100%;
    height: 350px;
    text-align: left;
    vertical-align: top;
    padding: 8px;
    resize: none;
}
.notice-container .noticeContentBox .contentText::placeholder{
    padding-top: 0px;
}
.noticeTitle, .contentText, .createDateText {
    flex-grow: 1;
    height: 60px;
    padding: 0 16px;
    border: 1px solid #ccc;
}
.notice .btn-wrap{
    margin-top: 36px;
    text-align: right;
    display: flex;
    justify-content: right;
}
.notice .btn-wrap .btnOk{
    width: 120px;
    height: 36px;
    border-radius: 50px;
    background-color: #3E3E3E;
    color: #fff;
    margin-right: 20px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}
.notice .btn-wrap .btnReset{
    background-color: #A6A0A0;
    border-radius: 50px;
    color: #fff;
    width: 120px;
    height: 36px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}