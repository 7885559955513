.main_banner{
    width: 100%;
    height: 770px;
    position: relative;
}
.main_banner::after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 618px;
    background-color: #F6F0FF;
    z-index: -1;
}
.main_banner .swiper-container{
    display: flex;
    padding-top: 142px;
    position: relative;
}
.main_banner .swiper-container .event-info{
    display: flex;
    flex-direction: column;
    margin-left: 400px;
    margin-top: 88px;
}
.main_banner .swiper-container .event-info .event-date{
    display: block;
    width: min-content;
    padding: 6px 22px 6px 15px;
    border-radius: 16px 16px 16px 0px;
    background-color: #000000;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    text-wrap: nowrap;
    margin-bottom: 60px;
}
.main_banner .swiper-container .event-info .title{
    font-size: 40px;
    font-weight: 700;
    color: #060606;
    margin-bottom: 20px;
}
.main_banner .swiper-container .event-info .addr{
    font-size: 15px;
    font-weight: 500;
    color: #060606;
    margin-bottom: 25px;
}
.main_banner .swiper-container .event-info .detail-link{
    font-size: 20px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
}
.main_banner .swiper-container .banner-swiper{
    float: right;
    transform: translateX(70px);
    width: 1000px;
    position: relative;
    border-radius: 6px 0px 0px 6px;
}
.main_banner .swiper-container .banner-swiper .swiper-slide{
    height: 570px;
    cursor: pointer;
}
.main_banner .swiper-container .banner-swiper .event-img{
    border-radius: 6px;
    height: 100%;
    width: 100%;
}
.main_banner .swiper-container .banner-swiper .event-img.id1{
    background-color: #75423e;
}
.main_banner .swiper-container .banner-swiper .event-img.id2{
    background-color: #57542e;
}
.main_banner .swiper-container .banner-swiper .event-img.id3{
    background-color: #4f6e45;
}
.main_banner .swiper-container .banner-swiper .event-img.id4{
    background-color: #486e69;
}
.main_banner .swiper-container .banner-swiper .event-img.id5{
    background-color: #4c3f5f;
}
.main_banner .swiper-container .banner-swiper .event-img.id6{
    background-color: #4f60f7;
}

.main_banner .swiper-container .event-info .swiper-tool{
    display: flex;
    align-items: center;
    margin-top: 190px;
}

/* pagination-bar */
.main_banner .swiper-container .event-info .swiper-pagination-wrapper {
    width: 150px;
    height: 3px;
    background-color: transparent;
    position: relative;
    margin-right: 8px;
}
.main_banner .swiper-container .event-info .swiper-pagination-background {
    width: 100%;
    height: 3px;
    background-color: #B2B2B2;
    position: absolute;
    top: 0;
    left: 0;
}
  
.main_banner .swiper-container .event-info .swiper-pagination-progress {
    height: 3px;
    width: 100%;
    background-color: #000000; /* 진행 바의 색상 */
    transition: width 0.3s ease; 
    position: absolute;
    top: 0;
    left: 0;
}

/* pagination-count */
.main_banner .swiper-container .event-info .swiper-pagination-info{
    margin-right: 74px;
}
.main_banner .swiper-container .event-info .swiper-pagination-info p{
    font-size: 16px;
    font-weight: 400;
}

/* swiper-arrow */
.main_banner .swiper-container .event-info .swiper-button-prev{
    width: 26px;
    height: 26px;
    position: static;
    margin-top: 0;
}
.main_banner .swiper-container .event-info .swiper-button-next{
    width: 26px;
    height: 26px;
    position: static;
    margin-top: 0;
}
.main_banner .swiper-container .event-info .swiper-button-next::after{
    display: none;
}
.main_banner .swiper-container .event-info .swiper-button-prev::after{
    display: none;
}

/* swiper-control */
.main_banner .swiper-container .event-info .autoplay-control{
    width: 28px;
    height: 28px;
    margin: 0px 8px;
    z-index: 10;
    cursor: pointer;
}
.main_banner .swiper-container .event-info .autoplay-control img{
    width: 100%;
    height: 100%;
}