.noticeView-container {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 100px;
}
.noticeView-container .image-css{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 700px;
}
.noticeView-container .headText {
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    margin-top: 80px;
    margin-bottom: 40px;
}
.noticeView-container .titleText .isEditing-btn{
    display: flex;
}
.noticeView-container .titleText .isEditing-btn button{
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    height: min-content;
    margin-left: 10px;
    padding: 8px 10px;
}
.noticeView-container .titleText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 4px solid #222222;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f9f9f9;
    height: 64px;
}
.noticeView-container .titleText input{
    padding: 8px 6px;
    width: 80%;
    border-radius: 6px;
    border: 1px solid #666666;
}
.noticeView-container .edit-textarea{
    margin: 20px 0px;
    width: 100%;
    height: 200px;
    border-radius: 6px;
    padding: 4px;
}
.noticeView-container .titleText p{
    font-weight: 700;
    font-size: 16px;
    padding: 20px 10px;
}
.noticeView-container .titleText .edit-btn{
    display: flex;
}
.noticeView-container .titleText .edit-btn .titleText-btn{
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    height: min-content;
    margin-left: 10px;
    padding: 12px 16px;
}
.noticeView-container .post-info {
    display: flex;
    align-items: center;
    padding: 20px 10px;
    background-color: #f9f9f9;
}
.noticeView-container .post-info  {
    width: 100%;
}
.noticeView-container .post-info  .detail-wrap{
    display: flex;
    flex-direction: column;
    margin-right: 12px;
}
.noticeView-container .post-info  .author-info{
    margin-bottom: 4px;
}
.noticeView-container .post-info .detail-info{
    width: 90%;
}
.noticeView-container .post-info  .detail-info .dateView{
    display: flex;
    justify-content: space-between;
}
.noticeView-container .post-info .profile-picture {
    width: 40px;
    height: 40px;
    background-color: #ccc;
    border-radius: 50%;
    margin-right: 10px;
}
.noticeView-container .post-info .detail-info .author-name{
    margin-bottom: 4px;
}
.noticeView-container .post-info  .detail-info .author-name, .noticeView-container .post-info  .detail-info .views, .noticeView-container .post-info  .detail-info .date, .noticeView-container .post-info  .author-info
, .noticeView-container .post-info .datetext {
    font-size: 14px;
    color: #666;
}
  
.noticeView-container .content{
    margin: 20px 0;
    line-height: 1.6;
    min-height: 200px;
}
.noticeView-container .file-wrap{
    align-items: center;
    border-top: 1px solid #e0e0e0;
    display: flex;
    height: 60px;
    justify-content: flex-end;
    margin-top: 80px;
}.noticeView-container .file-wrap.file-wrap2{
     justify-content: space-between;
 }
.noticeView-container .file-wrap.notice-file-wrap{
    align-items: center;
    border-top: 1px solid #e0e0e0;
    display: flex;
    height: 60px;
    justify-content: flex-start;
    margin-top: 80px;
}
.noticeView-container .file-wrap button{
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    height: min-content;
    margin-left: 10px;
    padding: 8px 10px;
}
.noticeView-container .file-wrap .file{
    margin-right: 10px;
}
.noticeView-container .file-wrap .file a{
    display: flex;
    align-items: center;
}
.noticeView-container .file-wrap svg {
    width: 14px;
    height: 14px;
    margin-right: 8px;
}
.noticeView-container .file-wrap p{
    font-weight: 800;
    color: #606060;
}
.noticeView-container .file-wrap {
    border-bottom: 4px solid #121212;
}
.noticeView-container .file-wrap .attachment-icon{
    margin-top: 20px;
    width: 20px;
    height: 20px;
    margin-bottom: 40px;
}
.noticeView-container .listBtn{
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #fff;
    color: #0097E0;
    border: 1px solid rgb(0, 0, 0, 10%);
    cursor: pointer;
}
/*댓글*/
.noticeView-container .comment-container{
    margin-top: 26px;
}
.noticeView-container .comment-container .title{
    margin-bottom: 20px;
}
.noticeView-container .comment-container .comment-x{
    width: 100%;
    text-align: center;
    padding: 20px 0px;
    border-bottom: 1px solid #a6a6a6;
}
.noticeView-container .comment-container .comment .comment-box{
    padding: 16px 0px;
    display: flex;
    border-bottom: 1px solid #a6a6a6;
    width: 100%;
}
.noticeView-container .comment-container .comment .comment-box .write-input{
    border: none;
    font-size: 16px;
    width: 75%;
    padding-left: 10px;
}
.noticeView-container .comment-container .comment .user-name{
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    font-size: 15px;
    width: 10%;
}
.noticeView-container .comment-container .comment .wall{
    height: 16px;
    margin: 8px 20px;
    width: 1px;
    background-color: #3e3e3e;
}
.noticeView-container .comment-container .comment .content{
    margin: 0px;
    font-size: 14px;
    width: 76%;
    margin-top: 5px;
    min-height: auto;
}
.noticeView-container .comment-container .comment .btn-box{
    margin-left: auto;
}
.noticeView-container .comment-container .comment .btn-box .btn{
    background-color: #f0f0f0;
    border: none;
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    font-weight: 400;
    height: min-content;
    margin-left: 10px;
}
.noticeView-container .comment-container .comment-write{
    position: relative;
    margin-bottom: 100px;
}
.noticeView-container .comment-container .login-x{
    text-align: center;
}
.noticeView-container .comment-container .comment-write .write{
    width: 100%;
    height: 32px;
    border: 1px solid #a6a6a6;
    padding: 8px;
    border-radius: 0px 0px 6px 6px;
    border-top: none;
}
.noticeView-container .comment-container .comment-write .enter-btn{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-40%);
}
.noticeView-container .comment-container .comment-write .enter-btn svg{
    transform: scaleY(-1);
}
.noticeView-container .file-btn{
    display: flex;
    align-items: center;
}
.noticeView-container .file-btn .btn{
    background-color: #3e3e3e;
    color: #fff;
    padding: 0 40px;
    flex-shrink: 0;
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.noticeView-container .file-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.noticeView-container .file-container .name-container{
    display: flex;
    align-items: center;
}
.noticeView-container .file-container .name-container .title{
    margin-right: 10px;
}
.noticeView-container .file-container .name-box{
    display: flex;
    align-items: center;
}
.noticeView-container .file-container .name-box:nth-child(1){
    margin-bottom: 4px;
}
.noticeView-container .file-container .name-box p{
    margin-right: 4px;
    color: #555555;
}
.noticeView-container .file-container .name-box button{
    font-size: 12px;
    background-color: #b5b5b5;
    color: #ffffff;
    padding: 4px 6px;
    border-radius: 4px;
    margin-right: 10px;
}