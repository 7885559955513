#kakaomap{
    height: 300px;
    width: 100%;
    position: relative;
    margin-bottom: 24px;
    border: 1px solid #e6e6e6;
}
#kakaomap .kakaourl{
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-content: center;
    z-index: 10;
}
#kakaomap .kakaourl .link{
    padding: 6px 8px;
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    margin-left: 6px;
    font-size: 16px;
    color: #111111;
    display: flex;
    align-items: center;
}
#kakaomap .kakaourl .link svg{
    width: 24px;
    height: 24px;
    fill: #797979;
}

