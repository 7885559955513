.changePw-bg{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 99;
}
.changePw-bg .container{
    padding: 40px;
    background-color: #ffffff;
    border-radius: 20px;
}
.changePw-bg .container .title{
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}
.changePw-bg .container .changePw-from{
    width: 400px;
}
.changePw-bg .container .changePw-from .inputBox{
    margin-bottom: 10px
}
.changePw-bg .container .changePw-from .inputBox-pw{
    margin-bottom: 20px
}
.changePw-bg .container .changePw-from .inputBox-headText{
    padding-left: 6px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 6px;
    width: 400px;
}
.changePw-bg .container .changePw-from .text-field{
    margin-bottom: 0;
    width: 400px;
    height: 50px;
    background-color: #ffffff;
    outline: none;
    border: none;
    padding: 0px 16px;
    box-shadow: 0px 2px 8px 2px rgb(0, 0, 0, 0.15);
    border-radius: 6px;
}
.changePw-bg .container .changePw-from .warningMsg{
    font-size: 12px;
    color: #FF7474;
    font-weight: 800;
    margin-top: 10px;
    margin-bottom: 10px;
}

.changePw-bg .container .btnOk{
    width: 400px;
    height: 50px;
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    background-color: #3E3E3E;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 20px;
}