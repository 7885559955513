.header{
    position: fixed;
    z-index: 99;
    width: 100vw;
    top: 0;
    display: block;
}
.header.none{
    display: none;
}
.header .header-container{
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 80px;
}
.header .sub-header{
    height: 0px;
    transition: 0.3s;
    overflow: hidden;
    display: flex;
    padding-left: 45.1%;
}
.header .sub-header .list{
    display: flex;
    flex-direction: column;
}
.header .sub-header .eventinfo-list{
    margin-left: 44px;
}
.header .sub-header .notice-list{
    margin-left: 142px
}
.header .sub-header .list p{
    font-size: 16px;
    color: #666;
    line-height: 19px;
    display: inline-block;
    letter-spacing: -0.8px;
    margin-bottom: 12px
}
.header .sub-header .list p:hover{
    color: #000000;
    font-weight: 500;
    text-decoration: underline;
}
.header.active{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
.header.on{
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
}
.header.on .sub-header{
    height: 110px;
    width: 100%;
}

.header .logo{
    height: 90px;
    width: 180px;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.header .logo img{
    width: 180px;
    height: 180px;
}
.header .navigation{
    display: flex;
    align-items: center;
}
.header .navigation .col{
    margin-right: 60px;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    position: relative;
}
.header .navigation .col.lastcol{
    margin-right: 0;
}
.header .navigation .col.active{
    font-weight: 700;
    color: #000000;
}
.header .navigation .col.active::before{
    content: '';
    display: block;
    background-color: #000000;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: -6px;
}
.header .navigation .col:hover{
    font-weight: 700;
    color: #000000;
}
.header .navigation .col:hover::before{
    content: '';
    display: block;
    background-color: #000000;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: -6px;
}

.header .right{
    display: flex;
    align-items: center;
    width: 115.4px;
}
.header .right .search{
    position: relative;
}
.header .right .search input[type="text"]{
    width: 292px;
    height: 42px;
    border: none;
    border-bottom: 1px solid #333333;
    padding-left: 10px;
    background-color: inherit;
}
.header .right .search input[type="text"]::placeholder{
    font-size: 16px;
    font-weight: 400;
}
.header .right .search #search-logo{
    display: none;
}
.header .right .search .search-logo{
    display: block;
    position: absolute;
    right: 2px;
    top: 6px;
    width: 26px;
    height: 26px;
}
.header .right .search .search-logo svg{
    fill: #333333;
}
.header .right .LoginLogout-btn{
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.header .right .LoginLogout-btn:hover{
    text-decoration: underline;
}

.header .right .user-link{
    width: 32px;
    height: 32px;
    border: 2px solid #333333;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 28px;
    cursor: pointer;
}
.header .right .user-like svg{
    fill: #000000;
}
