.main_section2{
    margin-top: 140px;
    padding: 0px 200px;
}
.main_section2 .main-title{
    display: flex;
    align-items: center;
    margin-left: 18px;
    margin: 20px;
}
.main_section2 .main-title h2{
    font-size: 20px;
    font-weight: 700;
}
.main_section2 .main-title .wall{
    margin: 0px 22px;
    width: 1px;
    height: 24px;
    background-color: #567FF2;
}
.main_section2 .main-title p{
    color: #567FF2;
    font-size: 15px;
    font-weight: 600;
}
.main_section2 .category-tab{
    width: 100%;
    height: 50px;
    background-color: #567FF2;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    cursor: pointer;
}
.main_section2 .category-tab .left{
    width: calc(50% - 1px);
}
.main_section2 .category-tab .right{
    width: calc(50% - 1px);
}
.main_section2 .category-tab .wall{
    width: 1px;
    height: 16px;
    background-color: #A3B9F5;
}
.main_section2 .category-tab p{
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    color: #ffffff;
    transition: 0.1s;
    opacity: 0.5;
}
.main_section2 .category-tab .active p{
    opacity: 1;
}

.main_section2 .main-event{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 48px;
}
.main_section2 .main-event .event{
    width: calc(17% - 14px);  /* 66% */
    margin-left: 16px;
    padding: 36px 20px 0px 40px;
    height: 380px;
    border-radius: 8px;
    position: relative;
    transition: 0.3s;
    overflow: hidden;
}
.main_section2 .main-event .event::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: rgba(0,0,0,0.5);
}
.main_section2 .main-event .event.active::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(rgba(0,0,0,0) 50%, rgba(0,0,0,0.5));
}
.main_section2 .main-event .event.event0{
    margin-left: 0;
}
.main_section2 .main-event .event .txt{
    margin-right: 36px;
    transform: rotate(90deg);
    transition: none;
}
.main_section2 .main-event .event .txt .event-title{
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
    text-wrap: nowrap;
}
/* main-event::hover */
.main_section2 .main-event .event.active{
    width: calc(66% - 20px);
}
.main_section2 .main-event .event.active .event-title{
    text-align: center;
}
.main_section2 .main-event .event.active .txt{
    transform: rotate(0deg);
    margin-top: 260px;
}
.main_section2 .token-x{
    text-align: center;
    padding: 160px 0px;
    color: #6a79ed;
    font-size: 20px;
    font-weight: 500;
}
