.fapp .headerbar{
    height: 90px;
}
.fapp .banner{
    height: 590px;
    width: 100%;
    background-color: blueviolet;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 160px;
}
.fapp .banner .title{
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 18px;
    color: #ffffff;
}
.fapp .banner .sub-title{
    font-size: 18px;
    font-weight: 300;
    color: #ffffff;
    margin-bottom: 50px;
}
.fapp .banner .underbar{
    width: 1200px;
    height: 1px;
    background-color: #ffffff;
}

.fapp .sec{
    margin: 0px 340px;
    margin-top: 100px;
}
.fapp .more-btn{
    float: right;
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.fapp .more-btn svg{
    width: 16px;
    height: 16px;
    fill: #000000;
    margin-bottom: 1px;
    margin-left: 2px;
}
.fapp .container{
    clear: both;
    display: flex;
    justify-content: space-between;
}
.fapp .container .title-hashtag{
    display: flex;
    flex-direction: column;
}
.fapp .container .title-hashtag .design-point{
    width: 24px;
    height: 4px;
    background-color: #333333;
    margin-bottom: 44px;
}
.fapp .container .title-hashtag .title{
    font-size: 28px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 30px;
}
.fapp .container .title-hashtag .sub-title{
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 70px;
}
.fapp .container .title-hashtag .hashtag p{
    font-size: 17px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 27px;
}
.fapp .container .img-list{
    display: flex;
    width: 900px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 60px;
}
.fapp .container .img-list .box{
    width: calc(33.3% - 16px);
    margin-bottom: 60px;
}
.fapp .container .img-list .box .img{
    width: 100%;
    padding: 50%;
    border-radius: 5px;
    margin-bottom: 14px;
    position: relative;
}
.fapp .container .img-list .box .img .like-btn{
    position: absolute;
    top: 15px;
    right: 15px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fapp .container .img-list .box .img .like-btn svg{
    width: 80%;
    height: 80%;
    margin-left: 1px;
}
.fapp .container .img-list .box .title{
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 14px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.fapp .container .img-list .box .address{
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    text-align: center;
}

/* moreArticle */
.morearticle{
    display: flex;
    position: relative;
}
.morearticle .event-list{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.morearticle .event-list .list{
    display: flex;
    width: 900px;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 300px;
}
.morearticle .event-list .box{
    width: calc(25% - 16px);
    margin-bottom: 60px;
}
.morearticle .event-list .box .img{
    width: 100%;
    padding: 50%;
    border-radius: 5px;
    margin-bottom: 14px;
    position: relative;
}
.morearticle .event-list .box .img .like-btn{
    position: absolute;
    top: 15px;
    right: 15px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.morearticle .event-list .box .img .like-btn svg{
    width: 80%;
    height: 80%;
    margin-left: 1px;
}
.morearticle .event-list .box .title{
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 14px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.morearticle .event-list .box .address{
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    text-align: center;
}
.morearticle .more-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 288px;
    height: 40px;
    margin-left: 300px;
    background: #F2F2F7;
    border: 1px solid #E6E6EB;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #666670;
}

.morearticle .sidebar{
    position: sticky;
    top: 120px;
    margin-left: 40px;
    padding-left: 40px;
    width: 300px;
    height: 810px;
    border-left: 1px solid #e1e1e1;
}
.morearticle .sidebar .hashtag-box{
    width: 300px;
    padding: 20px 0 10px 20px;
    margin-bottom: 30px;
    border-radius: 20px;
    background: #f5f5f5;
    display: flex;
    flex-wrap: wrap;
}
.morearticle .sidebar .hashtag-box p{
    display: flex;
    align-items: center;
    height: 35px;
    padding: 0 15px;
    margin: 0 10px 10px 0;
    border-radius: 15px;
    background: #fff;
    font-size: 14px;
    color: #999;
}


.morearticle .sidebar .recommend-list .main-title{
    margin-bottom: 15px;
    font-size: 18px;
    color: #000;
    font-weight: 600;
}
.morearticle .sidebar .recommend-list .recommend{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    height: 65px;
    margin-top: 15px;
}
.morearticle .sidebar .recommend-list .recommend:nth-child(1){
    margin-top: 0;
}
.morearticle .sidebar .recommend-list .recommend .txt{
    width: 185px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.morearticle .sidebar .recommend-list .recommend .txt .title{
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.morearticle .sidebar .recommend-list .recommend .txt p{
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.morearticle .sidebar .recommend-list .recommend .img{
    width: 100%;
    height: 65px;
    margin-left: 20px;
    border-radius: 10px;
}
