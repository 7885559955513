@import url('https://webfontworld.github.io/pretendard/Pretendard.css');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Pretendard";
}
textarea {
  resize: none;
}
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
img,video,svg{
    width: 100%;
    height: 100%;
}
button{
    border: none;
    background-color: initial;
}
a{
    text-decoration: none;
}
input::placeholder{
  font-family: "Pretendard";
}
body{
  overflow-x: hidden;
  position: relative;
}

/*test*/
.test-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.test-container li, .test-container ul {
    list-style-type: disc !important;
}
.backend-test {
    margin-left: 50px;
    width: 50%;
}
.frontend-test {
    width: 50%;
}

img{
    user-drag: none;
    -webkit-user-drag: none;
}
